// 
// user.scss
// Use this to write your custom SCSS
//

// region select_card
.select_card {
  cursor: pointer;
  .card-body {
    display: flex;
  }

  .icon-circle {
    width: 2.5rem;
    height: 2.5rem;

    i {
      font-size: 1.5rem;
    }

    img {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }

  .form-check {
    position: absolute;
    top: 0;
    right: 0;
  }
}
// endregion

// region menu
nav.navbar .dropdown-menu-xl {
  @include media-breakpoint-up(lg) {
    right: 0!important;
    left: auto!important;
    transform: none!important;
  }
  .dropdown-img-start {
    min-height: 250px;
    &::before {
      z-index: -1;
      background-color: $primary;
    }
  }
}
// endregion

// region footer
footer {
  .footer-brand {
    max-width: 250px;
  }
}
// endregion

// region account_ppe
.account_ppe {
  .list-group-item[data-bs-toggle="collapse"] {
    cursor: pointer;
    &[aria-expanded="true"] {
      .list-group-item-name {
        font-weight: bold;
      }
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        background: $primary;
      }
    }
  }
}
// endregion

// region editableField
@keyframes save_pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

[data-editable-field] {
  margin: 0!important;
  > [name] {
    height: 34px;
  }
}

[data-editable-field-submit] {
  transition: background-color 2s ease-in;
  &.pulse {
    animation: save_pulse 1.5s infinite;
  }
}
// endregion